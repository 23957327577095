import { NavLink } from '@remix-run/react';

import { useLocationHref } from '../../hooks/useLocationHref';
import { useMyOrganizationFeatureChecker } from '../Organization/hooks/organization';
import { SlackUtils } from '../Slack';

export function Navbar(props: { showConnectToSlack?: boolean }): JSX.Element {
  const featureChecker = useMyOrganizationFeatureChecker();
  const hasProgramAccess = featureChecker.hasAnyProgramAccess();
  const { showConnectToSlack } = props;
  const links = [
    { to: '/programs', text: 'Available Programs' },
    { to: '/channels', text: 'My Activated Programs' },
  ];
  const locationHref = useLocationHref();
  const onConnectClick = async () => {
    window.location.href = await SlackUtils.GenerateSlackInstallURL({
      scenario: 'connect',
      redirectTo: locationHref,
    });
  };
  return (
    <div className='w-full my-4 relative'>
      <div className='w-full flex items-end justify-center h-10 gap-10'>
        {links.map((link) => (
          <NavLink
            key={link.to}
            to={link.to}
            className={({ isActive }) =>
              isActive
                ? 'tracking-[1.25px] pb-1 text-white font-bold border-b-2'
                : 'tracking-[1.25px] pb-1 text-icon-gray'
            }
          >
            {link.text}
          </NavLink>
        ))}
      </div>
      {showConnectToSlack && hasProgramAccess && (
        // the outer layout has padding px-10, this is a hack to make the full
        // width of this banner.
        <div
          className='w-[calc(100%+80px)] -ml-10 h-15 
        bg-lp-red-001 bg-opacity-40 text-white 
         flex items-center justify-center gap-4 font-medium'
        >
          <div>Programs require a connected Slack workspace!</div>
          <div className='underline cursor-pointer' onClick={onConnectClick}>
            Add to Slack
          </div>
        </div>
      )}
    </div>
  );
}
